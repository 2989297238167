import './NoData.scss'

import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomPrimaryButton from "../buttons/primary/CustomPrimaryButton";
import address from "../../assests/error_state/light_no_addresses.svg";
import addressDark from "../../assests/error_state/dark_no_addresses.svg";
import auctionEnd from "../../assests/error_state/dark_auction_ended.svg";
import bankDark from "../../assests/error_state/dark_no_banks.svg";
import bankLight from "../../assests/error_state/light_no_banks.svg";
import cardLight from "../../assests/error_state/light_no_payment_cards.svg";
import carddark from "../../assests/error_state/dark_no_payment_cards.svg";
import darkEmpty from "../../assests/error_state/dark_empty.svg";
import emptyPaymentList from '../../assests/icons/no-data.png'
import lightEmpty from "../../assests/error_state/light_empty.svg";
import searchDark from '../../assests/error_state/dark_search.svg'
import searchLight from "../../assests/error_state/light_search.svg";

// Might need this code later
// const roomMsg = 'For now, there are no auction rooms available for you! Please pay us a visit soon.'
// const cardMsg = 'For now, there are no cards available for you! Please upload a card to view on dashboard and sell';
const auctionMsg = 'Sadly, the auction has concluded and cannot be played again.';
const noDataMsg = 'No data to flaunt!';
const auctionErrMsg = 'Auction Ended';
const lostConnectionMsg = 'Your connection is not active';
const lostConnectionDesc = 'You are disconnected. Please check your connection';
const emptyMsg = 'Oops... This is empty';
const emptyDesc = "Nothing here to show yet";
const emptyAddress = "No address added yet";
const emptyDescAddress = "Looks like you haven't added an address yet.";
const emptyCards = "No cards linked";
const emptyDescCards =
  "No card found. Let's add a card so you can top up your wallet!.";
const emptyBank = "No bank linked";
const emptyDescBank = "No bank found. Let’s add a bank!.";
const emptySearch = "Ahh... there is nothing";
const emptyDescSearch = "No luck this time! How about a different keyword?";


export const NoData = ({ type,isDark }) => {
  const navigate = useNavigate();
  const globalDarkMode = useSelector((state)=>state.profile?.globalDarkMode);

  let message, imgSrc, errMsg;

  switch (type) {
    case 'auction':
      message = auctionMsg;
      imgSrc = auctionEnd;
      errMsg = auctionErrMsg
      break;
    case 'card':
      errMsg = emptyCards
      message = emptyDescCards;
      imgSrc = globalDarkMode || isDark? carddark:cardLight; 
      break;
    case 'bank':
      errMsg = emptyBank
      message = emptyDescBank;
      imgSrc = globalDarkMode || isDark? bankDark:bankLight; 
      break;
    case 'payment':
      message = noDataMsg;
      imgSrc = emptyPaymentList;
      break;
    case 'room':
      errMsg = emptyMsg
      message = emptyDesc;
      imgSrc = globalDarkMode || isDark? darkEmpty:lightEmpty; 
      break;
    case 'search':
      errMsg = emptySearch;
      message = emptyDescSearch;
      imgSrc = globalDarkMode || isDark? searchDark:searchLight; 
      break;
    case 'address':
      errMsg = emptyAddress
      message = emptyDescAddress;
      imgSrc = globalDarkMode || isDark? addressDark:address; 
      break;
    case 'lostConnection':
      errMsg = lostConnectionMsg
      message = lostConnectionDesc;
      break;
    default:
      errMsg = emptyMsg
      message = emptyDesc;
      imgSrc = globalDarkMode || isDark? darkEmpty:lightEmpty; 
      break;
  }

  return (
    <div
      className={`no-cards-container ${type === "payment" ? " no-height" : ""}`}
    >
      <div
        className={`box-container ${
          isDark || globalDarkMode || type === "auction" ? "dark" : ""
        }`}
      >
        {imgSrc && <img src={imgSrc} alt={`${type}`} />}
        {errMsg && <div className="error text-sm-medium-14">{errMsg}</div>}
        <div className="contents text-sm-regular-14">{message}</div>
        {type === "auction" && (
          <CustomPrimaryButton onClick={() => navigate("/dashboard")}>
            Go to dashboard
          </CustomPrimaryButton>
        )}
      </div>
    </div>
  );
};



