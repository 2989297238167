import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  apiDeleteCall,
  apiPostCall,
  apiPutCall,
  getHeaders,
  handleSessionTimeout,
  isTokenExpired,
} from "../../utils/utils";

export const getAllAddress = createAsyncThunk("getAllAddress", async () => {
  if (isTokenExpired()) handleSessionTimeout();
  else
    return await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}api/address/`,
      {
        headers: getHeaders(),
      }
    );
});

export const getAllCorporateAddress = createAsyncThunk(
  "getAllCorporateAddress",
  async () => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/address/?is_corporate=true`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const addAddress = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/address/", userData, handleSuccess, handleFail);
};

export const updateAddress = (userData, handleSuccess, handleFail) => {
  apiPutCall("api/address/", userData, handleSuccess, handleFail);
};

export const deleteAddress = (userData, handleSuccess, handleFail) => {
  let url = `?address_id=${userData.id}`;
  apiDeleteCall(`api/address/${url}`, handleSuccess, handleFail);
};

const address = createSlice({
  name: "address",
  initialState: {
    isLoading: false,
    data: null,
    currentAddress: null,
    corporateAddresses: [],
  },
  reducers: {
    setCurrentAddress: (state, action) => {
      state.currentAddress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAddress.pending, (state, action) => {
      state.isLoading = false;
      state.data = null;
    });
    builder.addCase(getAllAddress.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(getAllAddress.rejected, (state, action) => {
      state.isLoading = false;
      state.data = action.error;
    });
    builder.addCase(getAllCorporateAddress.pending, (state, action) => {
      state.isLoading = false;
      state.corporateAddresses = [];
    });
    builder.addCase(getAllCorporateAddress.fulfilled, (state, action) => {
      state.isLoading = false;
      state.corporateAddresses = action.payload?.data?.data;
    });
    builder.addCase(getAllCorporateAddress.rejected, (state, action) => {
      state.isLoading = false;
      state.corporateAddresses = action.error;
    });
  },
});

export default address.reducer;
export const { setCurrentAddress } = address.actions;
