import React from 'react';
import { Navigate } from "react-router-dom";


export const PrivateRoute = ({ component: RouteComponent }) => {
  const isAuthenticated = localStorage.getItem("userDetails")
  const userID = isAuthenticated ? JSON.parse(isAuthenticated)?.id : "";

  if (userID) return <RouteComponent />;
  else return <Navigate to="/login" />;
}