import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  apiDeleteCall,
  apiPatchCall,
  apiPostCall,
  getHeaders,
  handleSessionTimeout,
  isTokenExpired,
} from "../../utils/utils";

export const getAllDashboardDetail = createAsyncThunk(
  "getAllDashboardDetail",
  async (data) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/dashboard/dashboard_data?start_date=${data.start}&end_date=${data.end}`,
        {
          headers: getHeaders(),
        }
      );
  }
);
export const getSoldAndOrderDetails = createAsyncThunk(
  "getSoldAndOrderDetails",
  async (data) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/dashboard/statistics?start_date=${data.start}&end_date=${data.end}`,
        {
          headers: getHeaders(),
        }
      );
  }
);
export const getOrdersAndEarnings = createAsyncThunk(
  "getOrdersAndEarnings",
  async (data) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/dashboard/order_data?start_date=${data.start}&end_date=${data.end}`,
        {
          headers: getHeaders(),
        }
      );
  }
);
export const getEarningReports = createAsyncThunk(
  "getEarningReports",
  async (data) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/dashboard/earning_data?start_date=${data.start}&end_date=${data.end}`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const getSellerCards = createAsyncThunk(
  "getSellerCards",
  async (data) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/seller_dashboard/?type=${
          data.room
        }&paginate=true&limit=20&page=${data.page}${
          data.sortBy ? `&order_by=${data.sortBy}` : ""
        }${data.room === "saved" ? "&is_corporate=False" : ""}`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const createTag = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/inventory_tag/", userData, handleSuccess, handleFail);
};

export const getAllInventoryTag = createAsyncThunk(
  "getAllInventoryTag",
  async (data) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/inventory_tag/?${
          data.page ? "paginate=true&limit=10&page=" + data.page : ""
        }${data?.searchQuery ? "&search_text=" + data?.searchQuery : ""}`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const getSellerHigestBid = createAsyncThunk(
  "getSellerHigestBid",
  async (data) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/seller_dashboard/?type=highest_bid&paginate=true&limit=10&page=1`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const updateInventoryTag = (
  inventoryData,
  handleSuccess,
  handleFail
) => {
  apiPatchCall("api/inventory_tag/", inventoryData, handleSuccess, handleFail);
};

export const deleteInventoryTag = (
  inventoryData,
  handleSuccess,
  handleFail
) => {
  let url = `?inventory_tag_id=${inventoryData.id}`;
  apiDeleteCall(`api/inventory_tag/${url}`, handleSuccess, handleFail);
};
export const toggleSeller = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/toggle/", userData, handleSuccess, handleFail);
};

const dashboardData = createSlice({
  name: "dashboardData",
  initialState: {
    isLoading: false,
    dashboardData: null,
  },

  extraReducers: (builder) => {
    builder.addCase(getAllDashboardDetail.pending, (state, action) => {
      state.isLoading = true;
      state.dashboardData = null;
    });
    builder.addCase(getAllDashboardDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashboardData = action.payload?.data;
    });
    builder.addCase(getAllDashboardDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.dashboardData = null;
    });
  },
});
const soldAndOrder = createSlice({
  name: "soldAndOrder",
  initialState: {
    isLoading: false,
    soldAndOrder: [],
  },

  extraReducers: (builder) => {
    builder.addCase(getSoldAndOrderDetails.pending, (state, action) => {
      state.isLoading = true;
      state.soldAndOrder = null;
    });
    builder.addCase(getSoldAndOrderDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.soldAndOrder = action.payload?.data;
    });
    builder.addCase(getSoldAndOrderDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.soldAndOrder = null;
    });
  },
});
const orderAndEarning = createSlice({
  name: "orderAndEarning",
  initialState: {
    isLoading: false,
    orderAndEarning: null,
  },

  extraReducers: (builder) => {
    builder.addCase(getOrdersAndEarnings.pending, (state, action) => {
      state.isLoading = true;
      state.orderAndEarning = null;
    });
    builder.addCase(getOrdersAndEarnings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orderAndEarning = action.payload?.data;
    });
    builder.addCase(getOrdersAndEarnings.rejected, (state, action) => {
      state.isLoading = false;
      state.orderAndEarning = null;
    });
  },
});
const earningReport = createSlice({
  name: "earningReport",
  initialState: {
    isLoading: false,
    earningReport: null,
  },

  extraReducers: (builder) => {
    builder.addCase(getEarningReports.pending, (state, action) => {
      state.isLoading = true;
      state.earningReport = null;
    });
    builder.addCase(getEarningReports.fulfilled, (state, action) => {
      state.isLoading = false;
      state.earningReport = action.payload?.data;
    });
    builder.addCase(getEarningReports.rejected, (state, action) => {
      state.isLoading = false;
      state.earningReport = null;
    });
  },
});

const inventorytag = createSlice({
  name: "inventorytag",
  initialState: {
    isLoading: false,
    allTagList: [],
  },

  extraReducers: (builder) => {
    builder.addCase(getAllInventoryTag.pending, (state, action) => {
      state.isLoading = true;
      state.allTagList = [];
    });
    builder.addCase(getAllInventoryTag.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allTagList = action.payload?.data;
    });
    builder.addCase(getAllInventoryTag.rejected, (state, action) => {
      state.isLoading = false;
      state.allTagList = [];
    });
  },
});

const sellerCards = createSlice({
  name: "sellerCards",
  initialState: {
    isLoading: false,
    allSellerCards: null,
    totalCount: 0,
    totalPages: 1,
  },

  extraReducers: (builder) => {
    builder.addCase(getSellerCards.pending, (state, action) => {
      state.isLoading = true;
      state.allSellerCards = null;
    });
    builder.addCase(getSellerCards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allSellerCards = action.payload?.data;
      state.totalCount = action.payload?.total_count;
      state.totalPages = action.payload?.total_pages;
    });
    builder.addCase(getSellerCards.rejected, (state, action) => {
      state.isLoading = false;
      state.allSellerCards = action.error;
    });
  },
});

const sellerHigestBidCards = createSlice({
  name: "sellerHigestBidCards",
  initialState: {
    isLoading: false,
    allSellerHigestBid: null,
    totalCount: 0,
    totalPages: 1,
  },

  extraReducers: (builder) => {
    builder.addCase(getSellerHigestBid.pending, (state, action) => {
      state.isLoading = true;
      state.allSellerHigestBid = null;
    });
    builder.addCase(getSellerHigestBid.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allSellerHigestBid = action.payload?.data;
      state.totalCount = action.payload?.total_count;
      state.totalPages = action.payload?.total_pages;
    });
    builder.addCase(getSellerHigestBid.rejected, (state, action) => {
      state.isLoading = false;
      state.allSellerHigestBid = action.error;
    });
  },
});

const seller = {
  sellerCards: sellerCards.reducer,
  sellerHigestBidCards: sellerHigestBidCards.reducer,
  inventorytag:inventorytag.reducer,
  dashboardData:dashboardData.reducer,
  soldAndOrder:soldAndOrder.reducer,
  orderAndEarning:orderAndEarning.reducer,
  earningReport:earningReport.reducer
};
export default seller;
