import React from "react";
import { Backdrop } from "@mui/material";
import card1 from "../../assests/login/card-a.svg";
import card2 from "../../assests/login/card-b.svg";
import "./PCLiveLoader.scss";

const PCLiveLoader = ({open,isAuction}) => {
  return isAuction ? (
    <div className="pclive-loader auction">
      <div className="card-1">
        <img src={card1} alt="card" height={80} />
      </div>
      <div className="card-2">
        <img src={card2} alt="card" height={80} />
      </div>
      <div className="text text-sm-medium-14">
        {" "}
        Hold your bids!! Auction will begin shortly.
      </div>
    </div>
  ) : (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backdropFilter: "blur(8px)",
      }}
      open={open}
    >
      <div className="pclive-loader">
        <div className="card-1">
          <img src={card1} alt="card" height={100} />
        </div>
        <div className="card-2">
          <img src={card2} alt="card" height={100} />
        </div>
        <div className="text text-sm-medium-14">NO MORE FOMO!</div>
      </div>
    </Backdrop>
  );
};

export default PCLiveLoader
