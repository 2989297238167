import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  apiDeleteCall,
  apiPostCall,
  apiPutCall,
  getHeaders,
  handleSessionTimeout,
  isTokenExpired,
} from "../../utils/utils";

export const getPaymentCards = createAsyncThunk(
  "getPaymentCards",
  async (data, handleSuccess, handleFail) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/payment/card/`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const getPaymentBank = createAsyncThunk(
  "getPaymentBank",
  async (data, handleSuccess, handleFail) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/bank_verification/`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const getWalletBalance = createAsyncThunk(
  "getWalletBalance",
  async (data, handleSuccess, handleFail) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/wallet_balance/`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const addPaymentCardAction = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/payment/card/", userData, handleSuccess, handleFail);
};

export const addPaymentbankAction = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/bank_verification/", userData, handleSuccess, handleFail);
};

export const updatePaymentCard = (userData, handleSuccess, handleFail) => {
  apiPutCall("api/payment/card/", userData, handleSuccess, handleFail);
};

export const deletePaymentCard = (userData, handleSuccess, handleFail) => {
  let url = `?payment_method_id=${userData.id}`;
  apiDeleteCall(`api/payment/card/${url}`, handleSuccess, handleFail);
};

export const deletePaymentBank = (userData, handleSuccess, handleFail) => {
  let url = `?bank_account_id=${userData.id}`;
  apiDeleteCall(`api/bank_verification/${url}`, handleSuccess, handleFail);
};

export const addWalletTopup = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/topup_wallet/", userData, handleSuccess, handleFail);
};

const paymentBank = createSlice({
  name: "paymentBank",
  initialState: {
    isLoading: false,
    allPaymentBank: null,
    currentAddress: null,
  },
  reducers: {
    setCurrentAddress: (state, action) => {
      state.currentAddress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentBank.pending, (state, action) => {
      state.isLoading = true;
      state.allPaymentBank = null;
    });
    builder.addCase(getPaymentBank.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPaymentBank = action.payload?.data;
    });
    builder.addCase(getPaymentBank.rejected, (state, action) => {
      state.isLoading = false;
      state.allPaymentBank = action.error;
    });
  },
});

const paymentCard = createSlice({
  name: "paymentCard",
  initialState: {
    isLoading: false,
    allPaymentCards: null,
    currentAddress: null,
  },
  reducers: {
    setCurrentAddress: (state, action) => {
      state.currentAddress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentCards.pending, (state, action) => {
      state.isLoading = true;
      state.allPaymentCards = null;
    });
    builder.addCase(getPaymentCards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPaymentCards = action.payload?.data;
    });
    builder.addCase(getPaymentCards.rejected, (state, action) => {
      state.isLoading = false;
      state.allPaymentCards = action.error;
    });
  },
});

const walletBalance = createSlice({
  name: "walletBalance",
  initialState: {
    isLoading: false,
    currentWalletBalance: null,
  },
  reducers: {
    setCurrentAddress: (state, action) => {
      state.currentAddress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWalletBalance.pending, (state, action) => {
      state.isLoading = true;
      state.currentWalletBalance = null;
    });
    builder.addCase(getWalletBalance.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentWalletBalance = action.payload?.data;
    });
    builder.addCase(getWalletBalance.rejected, (state, action) => {
      state.isLoading = false;
      state.currentWalletBalance = action.error;
    });
  },
});

const payment = {
  paymentBank: paymentBank.reducer,
  paymentCard: paymentCard.reducer,
  walletBalance: walletBalance.reducer,
};
export default payment;
