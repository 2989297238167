import "./CustomPrimaryButton.scss";

import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";

const CustomPrimaryButton = ({
  isDark,
  disabled,
  fullWidth,
  children,
  size,
  type,
  padding = "8px 14px",
  margin,
  isError,
  onClick,
  showToastWhileDisabled,
  ...rest
}) => {
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);

  const getOuterDivClassName = () => {
    let classNames = `text-sm-semibold-14 button-custom-primary-component ${
      (globalDarkMode || isDark) && "pclive-dark-mode"
    }`;
    if (fullWidth) classNames += " full-width";
    if (disabled) classNames += " disabled";
    if (size === "xl") classNames += " text-md-semibold-16 xl";
    if (type === "error") classNames += " error";
    return classNames;
  };

  return (
    <div
      className={getOuterDivClassName()}
      onClick={() => {
        if (showToastWhileDisabled && disabled) onClick();
      }}
    >
      <Button
        className={getOuterDivClassName()}
        variant="text"
        disabled={disabled}
        fullWidth={fullWidth}
        sx={{
          padding: padding,
          margin: margin,
          backgroundColor: isError ? "#F04438" : "#0E54CA",
          "&:hover": {
            backgroundColor: isError ? "#F97066" : "#004ABC",
          },
        }}
        onClick={onClick}
        {...rest}
      >
        {children}
      </Button>
    </div>
  );
};

export default CustomPrimaryButton;
